import React, { useState } from "react"
import PropTypes from "prop-types"

const Tile = ({TileWidth, TileHeight, bgImage, bgDiv, textTop, textBottom, className, textHover}) => {
  const [isDisplay, setIsDisplay] = useState(false)
  const onHoverDisplay = () => {
    if (textHover != null){
      setIsDisplay(!isDisplay)
    }

  }
  if (TileHeight === null) {
    TileHeight = TileWidth
  }

  return (
  <div className={className} style={{display: 'grid'}} 
  onMouseOver={onHoverDisplay} onMouseOut={onHoverDisplay} >
    <div style={{gridArea: '1/1'}}>
      {bgImage}
    </div>
    <div style={{gridArea: '1/1',
                 color: 'white', 
                 fontFamily: 'Montserrat', 
                 fontWeight: '400', 
                 display:  isDisplay ? 'none' : 'grid', 
                 justifyContent: 'space-between',
                 padding: '1vw',
                 zIndex: 1}}>
      <div style={{display: 'flex', alignSelf: 'flex-start'}}>{textTop}</div>
      <div style={{display: 'flex', alignSelf: 'flex-end'}}>{textBottom}</div>
    </div>
    <div style={{
                 gridArea: '1/1',
                 color: 'white', 
                 fontFamily: 'Montserrat', 
                 fontWeight: '400', 
                 display: 'grid',
                 justifySelf: 'stretch',

                 zIndex: 1}}>
      {bgDiv}
    </div>
        <div style={{
                  gridArea: '1/1',
                   opacity: '80%',
                   backgroundColor: 'black',
                   color: 'white',
                   display: isDisplay ? 'flex' : 'none',
                   justifyContent: 'center',
                   alignItems: 'center',
                   fontSize: '1.5rem',
                   zIndex: 99
                }}>{textHover}</div>
    
   
  </div>
  )
  }

Tile.propTypes = {
  TileWidth: PropTypes.string,
  TileHeight: PropTypes.string,
  bgImage: PropTypes.object,
  bgImageStyle: PropTypes.object,
  textTop: PropTypes.object,
  textBottom: PropTypes.object,
  textStyle: PropTypes.object,
  bgDiv: PropTypes.object,
  bgDivStyle: PropTypes.object,
  className: PropTypes.string,
  textHover: PropTypes.object
  
}
Tile.defaultProps = {
  TileWidth: '100vw',
  TileHeight: null,
  bgImage: null,
  bgImageStyle: null,
  textTop: null,
  textBottom: null,
  textStyle: null,
  bgDiv: null,
  bgDivStyle: null,
  classname: 'tile',
  textHover: null
}

export default Tile
