import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const ContactForm = ( ) => {
  const [name, setName] = useState("")
  const [organization, setOrganization] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const query = useStaticQuery(graphql`
  query SiteFormspreeQuery {
    site {
      siteMetadata {
          formlink
      }
    }
  }
`)

const handleChange = e => {
  switch (e.target.id) {
    case "name":
      setName(e.target.value)
      break
    case "organization":
      setOrganization(e.target.value)
      break
    case "email":
      setEmail(e.target.value)
      break
    case "message":
      setMessage(e.target.value)
      break
  }
}
  return (
    <div className="kwes-form" style={{
      width: '100%',
      height: '100%',
      backgroundColor: 'rgb(0,0,0, 0.80)',
      display: 'grid',
      alignItems: 'flex-end'
    }}>
    <form
      id="contactForm"
      action={query.site.siteMetadata.formlink}
      method="POST"
      style={{display: 'flex', 
              flexDirection: 'column',
              justifyContent: 'flex-end', 
              alignItems: 'center',
              width: '100%'
            }}
    >
      <input
        type="text"
        name="name"
        id="name"
        placeholder="name"
        value={name}
        onChange={handleChange}
      />
      <input
        type="text"
        name="organization"
        id="organization"
        placeholder="organization"
        value={organization}
        onChange={handleChange}
      />
      <input
        type="email"
        placeholder="e-mail*"
        name="email"
        id="email"
        value={email}
        onChange={handleChange}
        rules='required'
      />
      <textarea
        id="message"
        name="message"
        placeholder="your message*"
        value={message}
        onChange={handleChange}
        style={{resize: 'vertical', minHeight: '300px'}}
        rules='required'
      />
      <input type="submit" value="Submit" className="button" />
      
    </form>
    </div>
  )
}

ContactForm.propTypes = {
  serverLink: PropTypes.string,
}

ContactForm.defaultProps = {
  serverLink: ``,
}

export default ContactForm
