import React from "react"
import ImageBeans from "../components/imageBeans"
import ImageTane from "../components/imageTane"
import ImageNoir from "../components/imageNoir"
import ImageCappuccino from "../components/imageCappuccino"
import ImageSwan from "../components/imageSwan"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Tile from "../components/tile"
import ContactForm from "../components/contactForm"
import { IoLogoFacebook, IoLogoInstagram } from "react-icons/io"
import { useStaticQuery, graphql } from "gatsby"

const IndexPage = () => {
  const social =  useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social{
              facebook
              instagram
              email
            }
            contactForm
          }
        }
      }
    `
  )

  return (<Layout>
    <SEO title="Home" />
  <div className="tileContainer">
    <Tile className="tileNoir tile" 
          bgImage={<ImageNoir />} 
          textBottom={<p>
                        mon - fri: 08.30 - 18.00    sat: 10.00 - 18h    sun: 10.30 - 16.30           
                      </p>} 
          
          textHover={ <div>
                        <p>Opening hours</p>
                        <table>
                          <tr>
                            <td style={{textAlign: 'right'}}>mon:</td>
                            <td>08.30 - 18.00</td>
                          </tr>
                          <tr>
                            <td style={{textAlign: 'right'}}>tue:</td>
                            <td>08.30 - 18.00</td>
                          </tr>
                          <tr>
                            <td style={{textAlign: 'right'}}>wed:</td>
                            <td>08.30 - 18.00</td>
                          </tr>
                          <tr>
                            <td style={{textAlign: 'right'}}>thu:</td>
                            <td>08.30 - 18.00</td>
                          </tr>
                          <tr>
                            <td style={{textAlign: 'right'}}>fri:</td>
                            <td>08.30 - 18.00</td>
                          </tr>
                          <tr>
                            <td style={{textAlign: 'right'}}>sat:</td>
                            <td>10.00 - 18.00</td>
                          </tr>
                          <tr>
                            <td style={{textAlign: 'right'}}  >sun:</td>
                            <td>10.30 - 16.30</td>
                          </tr>
                        </table>
                          
            </div>}
          />
    <Tile className="tileCappuccino tile" 
          bgImage={<ImageCappuccino />} 
          textTop={<p>address<br />
                      Naamsestraat 49<br />
                      3000 Leuven<br />
                      Belgium</p>}
         textHover={<a href="https://goo.gl/maps/5divkjoDYPNdJTn8A">Find us on Google maps</a>}/>
    <Tile className="tileTane tile" 
          bgImage={<ImageTane />} 
          textTop={<p>We <br />drink <br />
                      coffee <br />by <br />
                      the <br />bucket</p>}
            textHover={<a href="https://tane.coffee/">Visit Tane website</a>}/>
    <Tile className="tileBeans tile" 
          bgDiv={<ContactForm serverLink={social.site.siteMetadata.contactForm}/>}
          bgImage={<ImageBeans />} 
    />
    <Tile className="tileSwan tile" 
          bgImage={<ImageSwan />} 
          bgDiv={<div style={{width: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'center', marginBottom: '1.45rem'}}>
                      <a href={social.site.siteMetadata.social.facebook}><IoLogoFacebook style={{fontSize: '2rem'}}/></a> 
                      <a href={social.site.siteMetadata.social.instagram}><IoLogoInstagram style={{fontSize: '2rem'}}/></a>
                  </div>} />
  </div>
  </Layout>)
}
export default IndexPage
